<template>
  <div>
    <vs-prompt
      @accept="submitData"
      title="Trocar senha"
      :active.sync="isDialogActiveLocal"
      accept-text="Salvar"
      cancel-text="Cancelar">

      <div>
        <div class="vx-col md:12 w-full mt-2">
          <vs-input v-model="dataOldPassword" class="mt-3 w-full" label="Senha Atual" type="password"
                    v-validate="'required'" name="change-password-old-password"/>
          <span class="text-danger text-sm" v-show="errors.has('change-password-old-password')">{{ errors.first('change-password-old-password') }}</span>

          <vs-input v-model="dataNewPassword" class="mt-3 w-full" label="Nova Senha" type="password"
                    v-validate="'required'" ref="password" name="change-password-new-password"/>
          <span class="text-danger text-sm" v-show="errors.has('change-password-new-password')">{{ errors.first('change-password-new-password') }}</span>

          <vs-input v-model="dataRepeatNewPassword" class="mt-3 w-full" label="Repita Nova Senha" type="password"
                    v-validate="'required|confirmed:password'" name="change-password-repeat-new-password"/>
          <span class="text-danger text-sm" v-show="errors.has('change-password-repeat-new-password')">{{ errors.first('change-password-repeat-new-password') }}</span>

        </div>
      </div>
    </vs-prompt>

  </div>
</template>

<script>
  export default {
    props: {
      isActivePrompt: {
        type: Boolean,
        required: true
      },
      data: {
        type: Object,
        default: () => {
        },
      }
    },
    watch: {
      isActivePrompt(val) {
        if (!val) return
        if (Object.entries(this.data).length === 0) {
          // this.initValues()
          // this.$validator.reset()
        } else {
          let {

            oldPassword,
            newPassword,
            repeatNewPassword,

          } = JSON.parse(JSON.stringify(this.data))

          this.dataOldPassword = oldPassword
          this.dataNewPassword = newPassword
          this.dataRepeatNewPassword = repeatNewPassword

          this.initValues()
        }
      }
    },
    data() {
      return {
        dataOldPassword: null,
        dataNewPassword: null,
        dataRepeatNewPassword: null,
      }
    },
    computed: {
      isDialogActiveLocal: {
        get() {
          return this.isActivePrompt
        },
        set(val) {
          if (!val) {
            this.$emit('closeDialog')
          }
        }
      },
      isFormValid() {
        return !this.errors.any()
      },
      activeUserInfo() {
        return this.$store.state.AppActiveUser
      },
    },
    methods: {
      initValues() {
        if (this.data.id) return
        this.dataOldPassword = ""
        this.dataNewPassword = ""
        this.dataRepeatNewPassword = ""
      },
      submitData() {
        this.$validator.validateAll().then(result => {
          if (result) {
            const payload = {
              username: this.activeUserInfo.username,
              oldPassword: this.dataOldPassword,
              newPassword: this.dataNewPassword,
              repeatPassword: this.dataRepeatNewPassword,
            }

            this.$store.dispatch("auth/updatePassword", payload)
              .then(() => {
                this.$vs.notify({
                  color: 'success',
                  title: 'Sucesso',
                  text: `Senha atualizada!`
                })
              })
              .catch((error) => {
                this.$vs.notify({
                  color: 'danger',
                  title: 'Erro',
                  text: error.data.message
                })
              })
            this.$emit('closeSidebar')
            this.initValues()
          }
        })
      },
    },

  }
</script>
<style>
  .vs-dialog {
    max-width: 450px !important;
  }
</style>
