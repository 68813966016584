<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo.displayName">

    <dialog-change-password :isActivePrompt="addNewDialogChangePassword"
                            @closeDialog="toggleDialogChangePassword"
                            :data="dialogChangePasswordData"/>

    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ activeUserInfo.displayName }}</p>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">

      <div class="con-img ml-3">
        <img key="onlineImg" :src="photoURL" alt="user-img" width="40"
             height="40" class="rounded-full shadow-md cursor-pointer block"/>
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">

          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
          @click="toggleDialogChangePassword(true)">
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2">Trocar Senha</span>
          </li>

          <vs-divider class="m-1"/>

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2">Sair</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
  import DialogChangePassword from "@/components/dialogs/DialogsChangePassword.vue";

  export default {
    components: {
      DialogChangePassword
    },
    data() {
      return {
        photoURL: require("@/assets/images/pages/unkown_user.png"),

        //Dialog Change Password
        addNewDialogChangePassword: false,
        dialogChangePasswordData: {},
      }
    },
    computed: {
      activeUserInfo() {
        return this.$store.state.AppActiveUser
      }
    },
    methods: {
      toggleDialogChangePassword(val = false) {
        this.addNewDialogChangePassword = val
      },
      logout() {
        if (localStorage.getItem("accessToken")) {
          localStorage.removeItem("accessToken")
          this.$router.push('/login').catch(() => {
          })
        }

        localStorage.removeItem('userInfo')

        this.$router.push('/login').catch(() => {
        })
      },
    }
  }
</script>
