/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  {
    url: "/",
    name: "Cron. de Contratos",
    slug: "contracts",
    icon: "AlignCenterIcon",
  },
  {
    url: '/files/notice',
    name: "Editais",
    slug: "files-notice",
    icon: "FileIcon",
  },
  {
    url: '/files/scope',
    name: "Escopo Obrigatório",
    slug: "files-scope",
    icon: "CheckSquareIcon",
  },
  {
    url: '/files/thematicMaps',
    name: "Mapas Temáticos",
    slug: "files-thematic-maps",
    icon: "MapIcon",
  },
  {
    url: '/files/implantedPhysics',
    name: "Físico Implantado",
    slug: "files-implated-physics",
    icon: "SettingsIcon",
  },
  {
    url: '/files/criticalAnalysis',
    name: "Análises Críticas",
    slug: "files-critical-analysis",
    icon: "MapPinIcon",
  },
  {
    url: '/files/releases',
    name: "Liberações",
    slug: "files-releases",
    icon: "FileTextIcon",
  },
  {
    url: '/files/financial',
    name: "Financeiro",
    slug: "files-financial",
    icon: "DollarSignIcon",
  },
  {
    url: '/users',
    name: "Usuários",
    slug: "users",
    icon: "UserIcon",
  },
]
